import React from 'react';
import { Button } from '@mantine/core';

type AutoScrollButtonProps = {
  userScrolling: boolean;
  onResumeAutoScroll: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const AutoScrollButton: React.FC<AutoScrollButtonProps> = React.memo(
  ({ userScrolling, onResumeAutoScroll }) => {
    // If user is not scrolling, don't render the button
    if (!userScrolling) return null;

    return (
      <Button
        size='sm'
        radius='xl'
        style={{
          position: 'absolute',
          bottom: '12px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1000,
          boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.2)',
        }}
        onClick={onResumeAutoScroll}
      >
        Resume Autoscrolling
      </Button>
    );
  }
);

export default AutoScrollButton;
