import React, { useRef } from 'react';
import env from 'env';
import { notifications } from '@mantine/notifications';
import { ActionIcon, Tooltip } from '@mantine/core';
import {
  IconThumbDown,
  IconThumbUp,
  IconThumbUpFilled,
  IconThumbDownFilled,
} from '@tabler/icons-react';
import { isAxiosError } from 'axios';
import './CandidateFeedback.css';
import { MouseEvent } from 'react';
import {
  campaignInfoAtomForCandidate,
  CandidateCampaignFeedback,
} from 'pages/editorv2/atoms';
import { useAtom } from 'jotai';
import { IconMessage } from '@tabler/icons-react';

import axios from '../../api/axiosConfig';

interface CandidateFeedbackProps {
  campaignId: string;
  candidateId: string;
}

const CandidateFeedback = ({
  campaignId,
  candidateId,
}: CandidateFeedbackProps) => {
  const candidateCampaignInfoKey = candidateId + '_' + campaignId;
  const [candidateInfo, setCandidateInfo] = useAtom(
    campaignInfoAtomForCandidate(candidateCampaignInfoKey)
  );

  const feedbackValue = candidateInfo.feedback;
  const hasComments = candidateInfo.hasComments;
  const setFeedback = (val: CandidateCampaignFeedback) =>
    setCandidateInfo({ feedback: val, _fetchedAt: undefined });

  const abortControllerRef = useRef<AbortController | null>(null);

  const handleCandidateCampaignFeedback = async (
    e: MouseEvent<HTMLButtonElement>,
    feedback: 'thumbs_up' | 'thumbs_down'
  ) => {
    e.stopPropagation();
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const updatedFeedback =
      feedback === feedbackValue ? 'no_feedback' : feedback;

    setFeedback(updatedFeedback); // optimistic UI update

    try {
      await axios.patch(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/feedback/${campaignId}`,
        {
          feedback: updatedFeedback,
        },
        { signal: controller.signal }
      );
    } catch (error) {
      if (isAxiosError(error) && error.code === 'ERR_CANCELED') {
        return;
      }
      console.error('Error updating candidate campaign feedback:', error);
      notifications.show({
        title: `Candidate feedback`,
        message: "There was an error updating candidate's feedback",
        color: 'red',
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Tooltip
        label='Mark candidate as qualified for the role'
        position='bottom'
        openDelay={400}
        style={{ opacity: 0.8 }}
      >
        <ActionIcon
          variant='transparent'
          onClick={(e) => handleCandidateCampaignFeedback(e, 'thumbs_up')}
        >
          {feedbackValue === 'thumbs_up' ? (
            <IconThumbUpFilled className='thumb-icon thumb-up' />
          ) : (
            <IconThumbUp
              color='var(--mantine-color-green-4)'
              className='clickable-icon thumb-icon thumb-up'
            />
          )}
        </ActionIcon>
      </Tooltip>
      <Tooltip
        label='Mark candidate as unqualified for the role'
        position='bottom'
        openDelay={400}
        style={{ opacity: 0.8 }}
      >
        <ActionIcon
          variant='transparent'
          onClick={(e) => handleCandidateCampaignFeedback(e, 'thumbs_down')}
        >
          {feedbackValue === 'thumbs_down' ? (
            <IconThumbDownFilled className='thumb-icon thumb-down' />
          ) : (
            <IconThumbDown
              color='var(--mantine-color-red-4)'
              className='clickable-icon thumb-icon thumb-down'
            />
          )}
        </ActionIcon>
      </Tooltip>
      {hasComments && (
        <Tooltip
          label='Comments added'
          position='bottom'
          openDelay={400}
          style={{ opacity: 0.8 }}
        >
          <ActionIcon variant='transparent'>
            <IconMessage className='comment-icon' />{' '}
          </ActionIcon>
        </Tooltip>
      )}
    </div>
  );
};

export default CandidateFeedback;
