export type IndeedJob = {
  jobCity: string;
  jobState: string;
  jobTitle: string;
};

export enum RequirementImportance {
  Major = 'MAJOR',
  Normal = 'NORMAL',
  Minor = 'MINOR',
  Info = 'INFO',
}

export enum QuestionType {
  Normal = 'NORMAL',
  AutoGenerated = 'AUTO_GENERATED',
  GradeOnly = 'GRADE_ONLY',
}

export interface CandidateRequirement {
  question: string;
  importance: RequirementImportance;
  llmGradingInstructions?: string;
  failIfZero: boolean;
  questionType: QuestionType;
  title?: string; // only for autogenerated at the moment
}

export type ScriptInfo = {
  jobDescription: string;
  scriptGenerationGuidelines: string;
  callIntro: string;
  backgroundInfo: string;
  callOutro: string;
  failedCallSms: string[];
  failedCallEmailSubject: string;
  failedCallEmailBody: string;
  webCallEmailBody: string;
  webCallEmailSubject: string;
  name: string;
  campaignId: string;
  passingScore: number;

  // Old campaign settings
  scheduleFollowUp: boolean;
  email: string;
  callNewApplicants: boolean;
  jobTitle: string;
  jobCity: string;
  jobState: string;
  callFromNumber: string;
  useScriptScaffolding: boolean;
  LLMModel: string;
  voiceName: string;
  rescheduleCallOnVoicemail: boolean;
  callRetryCount: number;
  callRetryHours: number;
  reminderEmailCount: number;
  reminderEmailHours: number;
  webCall: boolean;
  webLinkExpirationDays: number;
  superBoostedKeywords: string[];
};
