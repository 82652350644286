import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Table,
  Title,
  Center,
  Loader,
  Badge,
  ActionIcon,
  Skeleton,
  Drawer,
  Tooltip,
  CloseButton,
  Menu,
  rem,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { debounce } from 'lodash';
import env from 'env';
import {
  IconRefresh,
  IconChevronsRight,
  IconNotification,
  IconTrash,
  IconDotsVertical,
  IconPlayerPlay,
  IconPlayerPause,
} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import SortableHeader from 'components/common/SortableHeader';
import { convertUpperSnakeToTitle } from 'utils/formatUtils';
import { formatPhoneNumber } from 'utils/phoneUtils';
import { useAtomValue } from 'jotai';
import { useKeyboard } from 'hooks/useKeyboard';
import {
  useGetResumeURL,
  useGetTranscriptData,
} from 'hooks/useGetTranscriptData';
import { useUpdateCandidateCampaignReview } from 'hooks/useUpdateCandidateCampaignReview';
import {
  scheduleFollowUpAtom,
  candidateCampaignInfoDictAtom,
  campaignInfoAtomForCandidate,
} from 'pages/editorv2/atoms';
import { useAtom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';
import ReviewIndicator from 'components/transcript/ReviewIndicator';
import CandidateFeedback from 'components/transcript/CandidateFeedback';
import SupportWindow from 'components/transcript/SupportWindow';
import axios from 'api/axiosConfig';
import Transcript from 'components/transcript/Transcript';

import CampaignDetailStatsRow from './CampaignDetailStatsRow';
import './CampaignDetailsPage.css';
import {
  convertSecondsToMinutesAndSeconds,
  formatToLocalTime,
} from '../../../utils/dateUtils';

import 'index.css';

const CallStatus = {
  PENDING: 'PENDING',
  PAUSED: 'PAUSED', // only if candidate is paused, not overall campaign paused
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  CANCELLED: 'CANCELLED',
  VOICEMAIL: 'VOICEMAIL',
  NO_ANSWER: 'NO_ANSWER',
  INVALID_NUMBER: 'INVALID_NUMBER',
  INVITED: 'INVITED',
};

// const CallInitiatedBy = {
//   CAMPAIGN: 'CAMPAIGN',
//   AUTOMATIC_RETRY: 'AUTOMATIC_RETRY',
//   CANDIDATE_RESCHEDULED: 'CANDIDATE_RESCHEDULED',
//   INBOUND: 'INBOUND',
// };

const SortColumn = {
  LAST_UPDATED: 'last_updated',
  OVERALL_GRADE: 'overall_grade',
  COMPLETION_RATE: 'completion_rate',
  CALL_LENGTH_SEC: 'call_length_sec',
};

interface SortConfig {
  column: string;
  direction: 'ascending' | 'descending';
}

const COLLAPSE_THRESHOLD = 1400; // Note: threshold here AND css file

const PAGINATION_AMOUNT = 50;

const CampaignDetailsPage = ({ isWebCall }) => {
  const [sort, setSort] = useState<SortConfig | undefined>({
    column: SortColumn.COMPLETION_RATE,
    direction: 'descending',
  });
  const handleSort = (column: string) => () => {
    setIsFetchingCalls(true);
    if (sort && sort.column === column) {
      if (sort.direction === 'descending')
        setSort({ column, direction: 'ascending' });
      else setSort(undefined);
    } else {
      setSort({ column, direction: 'descending' });
    }
  };

  const userId = localStorage.getItem('userId');

  const scheduleFollowUp = useAtomValue(scheduleFollowUpAtom); // Subscribes to atom updates
  const [, setCandidateCampaignInfoDictAtom] = useAtom(
    candidateCampaignInfoDictAtom
  ); // Subscribes to atom updates
  // const [, setScriptInfoAtom] = useAtom(scriptInfoAtom);
  const [isCollapsed, setIsCollapsed] = useState(
    window.innerWidth <= COLLAPSE_THRESHOLD
  );

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth <= COLLAPSE_THRESHOLD);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on window width

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [totalCount, setTotalCount] = useState(0);
  const [isFetchingCalls, setIsFetchingCalls] = useState(true);
  const [candidates, setCandidates] = useState<any>([]);
  const [meetingTimes, setMeetingTimes] = useState<
    Record<string, string | null>
  >({});
  const [selectedCandidateId, setSelectedCandidateId] = useState<string | null>(
    null
  );

  const [transcriptOpened, { open: openTranscript, close: closeTranscript }] =
    useDisclosure(false);

  const location = useLocation();
  const match = location.pathname.match(
    /\/scripts\/script-editor\/(new(?:-phone|-web)?\/)?([^/]+)/
  );
  const isNew = !!match?.[1]; // 'new/' part exists if match[1] is truthy

  const { campaignId: paramCampaignId } = useParams();
  const campaignId = match ? match[2] : paramCampaignId || '';

  const fetchLockRef = useRef<string | null>(null);

  const { handleUpdateChangesReviewed } = useUpdateCandidateCampaignReview();

  const handleArrowKeyPress = (step: number) => {
    if (!selectedCandidateId) {
      return;
    }

    const currentIndex = candidates.findIndex(
      (candidate) => candidate.candidate.candidate_id === selectedCandidateId
    );

    if (currentIndex === -1) {
      return;
    }

    const nextCandidateIndex = currentIndex + step;

    if (nextCandidateIndex < 0 || nextCandidateIndex >= candidates.length) {
      return;
    }

    const nextCandidate = candidates[nextCandidateIndex];
    setSelectedCandidateId(nextCandidate.candidate.candidate_id);
  };

  useKeyboard('ArrowUp', () => {
    handleArrowKeyPress(-1);
  });

  useKeyboard('ArrowDown', () => {
    handleArrowKeyPress(1);
  });

  const fetchCandidates = useCallback(
    async ({ skip, limit, silent = false }) => {
      if (!campaignId) return;
      if (fetchLockRef.current && silent) {
        // don't perform silent fetches if active fetch in progress
        return;
      }
      const refLockKey = '' + uuidv4();
      try {
        fetchLockRef.current = refLockKey;
        if (!silent) {
          setIsFetchingCalls(true);
        }
        const payload = {
          sort: {
            column: sort?.column || SortColumn.LAST_UPDATED,
            descending: sort?.direction === 'descending' || !sort?.direction,
          },
          isNew: isNew,
        };
        const fetchedAt = Date.now();
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/campaigns/campaign/${campaignId}/candidates_and_calls`,
          payload,
          {
            params: {
              skip,
              limit,
              userId,
            },
          }
        );
        if (fetchLockRef.current === refLockKey) {
          // don't set candidates if newer fetch started
          setCandidates((prev) => {
            if (silent) {
              return [...response.data.candidates];
            }
            return [...prev, ...response.data.candidates]; // Append new candidates
          });
          setTotalCount(Number(response.data['total_records']));
          // Set candidate info atoms to be globally accessible
          setCandidateCampaignInfoDictAtom((prev) => {
            const updatedCampaignInfos = response.data.candidates.reduce(
              (acc, c) => {
                const candidateCampaignInfoKey =
                  c.candidate.candidate_id + '_' + campaignId;
                acc[candidateCampaignInfoKey] = {
                  feedback: c.campaign_info.feedback,
                  changesReviewed: !c.review_items?.changes_needs_review,
                  isCallReviewed: !c.review_items?.call_needs_review,
                  isSMSReviewed: !c.review_items?.sms_needs_review,
                  isEmailReviewed: !c.review_items?.email_needs_review,
                  hasComments: c.has_comments,
                  _fetchedAt: fetchedAt,
                };
                return acc;
              },
              { ...prev }
            );
            return updatedCampaignInfos;
          });
        }
      } catch (error) {
        console.error('Error fetching campaign calls:', error);
        if (!isNew) {
          notifications.show({
            title: 'There was an error retrieving the campaign calls',
            message: error instanceof Error ? error.message : '',
            color: 'red',
          });
        }
      } finally {
        setIsFetchingCalls(false);
        fetchLockRef.current = null;
      }
    },
    [
      setTotalCount,
      setCandidates,
      campaignId,
      sort,
      isNew,
      setCandidateCampaignInfoDictAtom,
      userId,
    ]
  );

  const candidateIdsNeedMeetingFetched = useMemo(() => {
    return candidates
      .map((candidate) => candidate.candidate.candidate_id)
      .filter((id) => !(id in meetingTimes));
  }, [candidates, meetingTimes]);

  const fetchMeetingTimes = useCallback(
    async () => {
      if (!campaignId) return;

      try {
        // If all candidate_ids already have meeting times, do nothing
        if (candidateIdsNeedMeetingFetched.length === 0) return;

        // Fetch meeting times for missing candidate_ids
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/campaign/get_meeting_times/${campaignId}`,
          { candidate_ids: candidateIdsNeedMeetingFetched } // Pass candidate_ids as a payload
        );

        const fetchedMeetingTimes = response.data || {};
        // Transform the map into a dictionary (no transformation needed if it's already in the right format)
        const newMeetingTimesMap = Object.entries(fetchedMeetingTimes).reduce(
          (acc, [candidate_id, meeting_time]) => {
            acc[candidate_id] = meeting_time || null;
            return acc;
          },
          {}
        );
        // Merge new meeting times with the existing meetingTimesMap
        setMeetingTimes((prevMeetingTimesMap) => ({
          ...prevMeetingTimesMap,
          ...newMeetingTimesMap,
        }));
      } catch (error) {
        console.error('Error fetching meeting times:', error);
        notifications.show({
          title: 'Error retrieving meeting times',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
        const noMeetingTimesMap = candidateIdsNeedMeetingFetched.reduce(
          (map, id) => {
            map[id] = null; // Set the default value for each id
            return map;
          },
          {}
        );
        setMeetingTimes((prevMeetingTimesMap) => ({
          ...prevMeetingTimesMap,
          ...noMeetingTimesMap,
        }));
      }
    },
    [campaignId, candidateIdsNeedMeetingFetched] // Add dependencies
  );

  useEffect(() => {
    if (!campaignId) return;

    fetchMeetingTimes();
  }, [campaignId, fetchMeetingTimes]);

  const debouncedFetchCandidates = useMemo(
    () =>
      debounce((limit: number, skip: number, silent: boolean = false) => {
        if (!campaignId) return;
        if (!silent) {
          setIsFetchingCalls(true);
        }
        if (skip === 0 && !silent) {
          setCandidates([]);
          setTotalCount(0);
        }
        fetchCandidates({
          limit: limit,
          skip: skip,
          silent: silent,
        });
      }, 300), // 300ms debounce delay
    [fetchCandidates, campaignId] // Only recreate if fetchCandidates changes
  );

  useEffect(() => {
    let delay = 10_000;

    const handleInterval = () => {
      debouncedFetchCandidates(
        Math.max(candidates.length, PAGINATION_AMOUNT),
        0,
        true
      );
      if (delay < 1_000_000) {
        delay *= 3;
      }
      clearInterval(intervalId);
      intervalId = setInterval(handleInterval, delay);
    };

    let intervalId = setInterval(handleInterval, delay);

    return () => clearInterval(intervalId);
  }, [debouncedFetchCandidates, candidates.length]);

  useEffect(() => {
    if (!campaignId) return;
    setCandidates([]);
    setTotalCount(0);
    // Reset pagination when campaignId or sort changes
    debouncedFetchCandidates(PAGINATION_AMOUNT, 0);
  }, [campaignId, sort, debouncedFetchCandidates]);

  const { transcriptData, transcriptError, isFetchingTranscript } =
    useGetTranscriptData({
      candidateId: selectedCandidateId || '',
      campaignId,
    });

  useGetResumeURL({
    candidateId: selectedCandidateId || '',
    campaignId,
  });

  const handleRowClick = (candidateId: string) => {
    setSelectedCandidateId(candidateId);
    openTranscript();
    handleUpdateChangesReviewed({
      campaignId: campaignId,
      candidateId: candidateId || '',
      userId: userId || '',
      changesReviewed: { changesReviewed: true },
    });
  };

  const candidateToCallStatusDisplay = (candidate) => {
    const { calls } = candidate;
    if (calls && calls.length > 0) {
      const lastCall = calls[0];
      if (lastCall.call_status === CallStatus.PENDING) {
        // Removed the below because not enough room but this was the request:
        // if (lastCall.call_initiated_by === CallInitiatedBy.AUTOMATIC_RETRY) {
        //   // return 'Automatic Callback Pending';
        // }
        // if (
        //   lastCall.call_initiated_by === CallInitiatedBy.CANDIDATE_RESCHEDULED
        // ) {
        //   // return 'Callback Pending';
        // }
        return 'Pending';
      }
      return convertUpperSnakeToTitle(lastCall.call_status);
    }
  };

  const candidateToCallStatus = (candidate) => {
    const { calls } = candidate;
    if (calls && calls.length > 0) {
      const lastCall = calls[0];
      return lastCall.call_status;
    }
    return null;
  };

  const candidatePaused = (candidate) => {
    return candidateToCallStatus(candidate) === CallStatus.PAUSED;
  };

  const candidatePending = (candidate) => {
    return candidateToCallStatus(candidate) === CallStatus.PENDING;
  };

  const candidateToSmsStatus = (campaign_info) => {
    return convertUpperSnakeToTitle(campaign_info?.sms_status || 'NONE');
  };

  // TODO: just return is_done on the call
  const hasCallHappened = (call) =>
    call?.call_status !== CallStatus.PENDING &&
    call?.call_status !== CallStatus.INVITED &&
    call?.call_status !== CallStatus.CANCELLED;

  const candidateToRecentCallLength = (candidate) => {
    const max_call_length_sec = candidate.max_call_length_sec;
    const numCalls = candidateToNumberOfCalls(candidate);
    return max_call_length_sec && numCalls > 0
      ? convertSecondsToMinutesAndSeconds(max_call_length_sec)
      : '-';
  };

  const candidateToCompletionRate = (candidate) => {
    const numCalls = candidateToNumberOfCalls(candidate);
    if (numCalls === 0) {
      return '-';
    }
    if (!candidate.max_completion_rate && candidate.max_completion_rate !== 0) {
      return '-';
    }
    return `${(candidate.max_completion_rate * 100).toFixed(0)}%` || '-';
  };

  const candidateToOverallGrade = (candidate) => {
    const numCalls = candidateToNumberOfCalls(candidate);
    if (numCalls === 0) {
      return '-';
    }
    if (!candidate.max_overall_grade && candidate.max_overall_grade !== 0) {
      return '-';
    }
    return `${candidate.max_overall_grade?.toFixed(0)}%` || '-';
  };

  const candidateToLastCalled = (candidate) => {
    const numCalls = candidateToNumberOfCalls(candidate);

    return numCalls > 0
      ? formatToLocalTime(candidate.max_last_updated) || '-'
      : '-';
  };

  const candidateToNumberOfCalls = (candidate) => {
    return (
      candidate.candidate.calls.filter((c) => hasCallHappened(c)).length || 0
    );
  };

  const formatMeetingTime = (timestamp: string | null) => {
    if (!timestamp) return '-';
    return formatToLocalTime(timestamp);
  };

  const handleTranscriptClose = useCallback(() => {
    closeTranscript();
    setSelectedCandidateId(null);
  }, [closeTranscript]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const drawerElements = document.querySelectorAll('.mantine-Drawer-inner');
      const isClickInsideAnyDrawer = Array.from(drawerElements).some(
        (drawerElement) => drawerElement.contains(e.target as Node)
      );
      const trElement = (e.target as HTMLElement).closest('tr');

      if (isClickInsideAnyDrawer || trElement) {
        // If click is inside the drawer or on a <tr>, do nothing
        return;
      }

      // Otherwise, close the drawer
      handleTranscriptClose();
    };

    if (transcriptOpened) {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [transcriptOpened, handleTranscriptClose]);

  const candidateCampaignInfoKey = selectedCandidateId + '_' + campaignId;
  const [candidateInfo] = useAtom(
    campaignInfoAtomForCandidate(candidateCampaignInfoKey)
  );
  const showMarkAsUnreviewedButton = candidateInfo.changesReviewed;

  const handleMarkAsUnreviewed = (e) => {
    e.stopPropagation();
    handleUpdateChangesReviewed({
      changesReviewed: { changesReviewed: false },
      campaignId: campaignId || '',
      candidateId: selectedCandidateId || '',
      userId: userId || '',
    });
  };

  const [isHeaderHovered, setIsHeaderHovered] = useState(false); // Add this line

  const handleToggleStatusClick = async (
    candidateId: string,
    currentStatus: string
  ) => {
    const action = currentStatus === CallStatus.PAUSED ? 'resume' : 'pause';
    const newStatus =
      currentStatus === CallStatus.PAUSED
        ? CallStatus.PENDING
        : CallStatus.PAUSED;
    try {
      await axios.post(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/campaign/${campaignId}/${action}`
      );
      setCandidates((prevCandidates) => {
        const updatedCandidates = prevCandidates.map((item) =>
          item.candidate.candidate_id === candidateId
            ? {
                ...item,
                candidate: {
                  ...item.candidate,
                  calls:
                    item.candidate.calls && item.candidate.calls.length > 0
                      ? [
                          {
                            ...item.candidate.calls[0],
                            call_status: newStatus,
                          },
                          ...item.candidate.calls.slice(1),
                        ]
                      : item.candidate.calls,
                },
              }
            : item
        );
        return updatedCandidates;
      });
    } catch (error) {
      console.error('Error updating candidate call status:', error);
      notifications.show({
        title: 'Error updating candidate call status',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
    }
  };

  const handleRemoveClick = async (candidateCampaignId: string) => {
    const originalCandidates = [...candidates];
    // optimistic update
    setCandidates((prevCandidates) =>
      prevCandidates.filter(
        (candidate) =>
          candidate.campaign_info.candidate_campaign_id !== candidateCampaignId
      )
    );
    try {
      await axios.post(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateCampaignId}/remove`
      );
    } catch (error) {
      console.error('Error removing candidate:', error);
      notifications.show({
        title: 'Error removing candidate',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
      // rollback optimistic update on failure
      setCandidates(originalCandidates);
    }
  };

  return (
    <div className='campaign-details-page' style={{ marginTop: '0pt' }}>
      <CampaignDetailStatsRow campaignId={campaignId} isWebCall={isWebCall} />
      <div
        className='common-table-wrapper'
        style={{
          maxHeight: 'calc(100vh - 160px)',
          overflowY: 'scroll',
          background: 'white',
        }}
        onScroll={(e) => {
          const element = e.target as HTMLElement;

          if (
            element.scrollHeight - element.scrollTop <=
              element.clientHeight + 20 &&
            !isFetchingCalls
          ) {
            if (candidates.length >= totalCount) return;

            setIsFetchingCalls(true);
            debouncedFetchCandidates(PAGINATION_AMOUNT, candidates.length);
          }
        }}
      >
        <Table style={{ minWidth: '800px' }}>
          <Table.Thead style={{ position: 'relative' }}>
            <Table.Tr>
              <Table.Th
                style={{
                  width: '20%',
                }}
              >
                Name
              </Table.Th>
              {!isCollapsed && (
                <Table.Th
                  style={{
                    width: '20%',
                  }}
                >
                  {isWebCall ? 'Email' : 'Phone'}
                </Table.Th>
              )}

              <Table.Th
                style={{
                  width: '20%',
                }}
              >
                {isWebCall ? 'Called At' : 'Last Called'}
              </Table.Th>
              <SortableHeader
                sorted={sort?.column === SortColumn.CALL_LENGTH_SEC}
                reversed={sort?.direction === 'ascending'}
                onSort={handleSort(SortColumn.CALL_LENGTH_SEC)}
                style={{
                  width: '15%',
                }}
                className='no-ellipses'
              >
                Call Length
              </SortableHeader>
              {!isWebCall && (
                <Table.Th
                  style={{
                    width: '10%',
                  }}
                >
                  Calls
                </Table.Th>
              )}
              <SortableHeader
                sorted={sort?.column === SortColumn.OVERALL_GRADE}
                reversed={sort?.direction === 'ascending'}
                onSort={handleSort(SortColumn.OVERALL_GRADE)}
                style={{
                  width: '11%',
                }}
                className='no-ellipses'
              >
                Score
              </SortableHeader>
              <SortableHeader
                sorted={sort?.column === SortColumn.COMPLETION_RATE}
                reversed={sort?.direction === 'ascending'}
                onSort={handleSort(SortColumn.COMPLETION_RATE)}
                style={{
                  width: '14%',
                }}
                className='no-ellipses'
              >
                Completion
              </SortableHeader>

              <Table.Th
                style={{
                  width: '12%',
                }}
              >
                Feedback
              </Table.Th>

              {!isCollapsed && !isWebCall && (
                <Table.Th
                  style={{
                    width: '20%',
                  }}
                >
                  SMS Status
                </Table.Th>
              )}

              <Table.Th
                style={{
                  width: '15%',
                }}
              >
                Call Status
              </Table.Th>
              {scheduleFollowUp && !isCollapsed && (
                <Table.Th
                  style={{
                    width: '10%',
                  }}
                >
                  Meeting
                </Table.Th>
              )}

              <Table.Th
                style={{
                  width: '5%',
                  padding: '0',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center' /* Center horizontally */,
                    alignItems: 'center' /* Center vertically */,
                    height: '100%',
                    color: 'var(--salv-dark-6)',
                    marginRight: '5px',
                  }}
                  className='refreshIconContainer'
                >
                  <ActionIcon
                    variant='transparent'
                    className='refreshIconContainer'
                    onClick={() => {
                      setIsFetchingCalls(true);
                      setCandidates([]);
                      debouncedFetchCandidates(PAGINATION_AMOUNT, 0);
                    }}
                  >
                    <IconRefresh className='refreshIconContainer' size={16} />
                  </ActionIcon>
                </div>
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {candidates.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Table.Tr
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRowClick(item.candidate.candidate_id);
                    }}
                    bg={
                      selectedCandidateId === item.candidate.candidate_id
                        ? 'var(--mantine-color-gray-1)'
                        : undefined
                    }
                    // onClick={() => toggleRow(item.full_name)}
                  >
                    <Table.Td
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                      }}
                    >
                      <ReviewIndicator
                        campaignId={campaignId}
                        candidateId={item.candidate.candidate_id}
                        review='Overall'
                      />
                      {item.candidate.full_name}
                    </Table.Td>
                    {!isCollapsed && (
                      <Table.Td>
                        {isWebCall
                          ? item.candidate.email
                          : formatPhoneNumber(item.candidate.phone_number)}
                      </Table.Td>
                    )}
                    <Table.Td>{candidateToLastCalled(item)}</Table.Td>
                    <Table.Td>{candidateToRecentCallLength(item)}</Table.Td>
                    {!isWebCall && (
                      <Table.Td>{candidateToNumberOfCalls(item)}</Table.Td>
                    )}
                    <Table.Td>{candidateToOverallGrade(item)}</Table.Td>
                    <Table.Td>{candidateToCompletionRate(item)}</Table.Td>

                    <Table.Td
                      style={{
                        width: '8%',
                      }}
                    >
                      <CandidateFeedback
                        campaignId={campaignId}
                        candidateId={item.candidate.candidate_id}
                      />
                    </Table.Td>

                    {!isCollapsed && !isWebCall && (
                      <Table.Td className='no-ellipses'>
                        <Badge autoContrast className='status-badge'>
                          {candidateToSmsStatus(item.campaign_info)}
                        </Badge>
                      </Table.Td>
                    )}

                    <Table.Td className='no-ellipses'>
                      <Badge autoContrast className='status-badge'>
                        {candidateToCallStatusDisplay(item.candidate)}
                      </Badge>
                    </Table.Td>
                    {scheduleFollowUp && !isCollapsed && (
                      <Table.Td className='no-ellipses'>
                        {candidateIdsNeedMeetingFetched.includes(
                          item.candidate.candidate_id
                        ) ? (
                          <Skeleton height={8} />
                        ) : (
                          formatMeetingTime(
                            meetingTimes[item.candidate.candidate_id] || null
                          )
                        )}
                      </Table.Td>
                    )}

                    <Table.Td
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      className='no-ellipses'
                    >
                      <Menu shadow='md' width={200}>
                        <Menu.Target>
                          <ActionIcon variant='subtle'>
                            <IconDotsVertical size={20} />
                          </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                          <Menu.Label>{item.candidate.full_name}</Menu.Label>
                          {(candidatePaused(item.candidate) ||
                            candidatePending(item.candidate)) && (
                            <Menu.Item
                              leftSection={
                                candidatePaused(item.candidate) ? (
                                  <IconPlayerPlay
                                    style={{
                                      width: rem(14),
                                      height: rem(14),
                                    }}
                                  />
                                ) : (
                                  <IconPlayerPause
                                    style={{
                                      width: rem(14),
                                      height: rem(14),
                                    }}
                                  />
                                )
                              }
                              onClick={() => {
                                handleToggleStatusClick(
                                  item.candidate.candidate_id,
                                  candidateToCallStatus(item.candidate)
                                );
                              }}
                            >
                              {candidatePaused(item.candidate)
                                ? 'Resume'
                                : 'Pause'}
                            </Menu.Item>
                          )}

                          <Menu.Divider />
                          <Menu.Item
                            color='red'
                            leftSection={
                              <IconTrash
                                style={{ width: rem(14), height: rem(14) }}
                              />
                            }
                            onClick={() => {
                              handleRemoveClick(
                                item.campaign_info.candidate_campaign_id
                              );
                            }}
                          >
                            Remove
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>
                    </Table.Td>
                  </Table.Tr>
                </React.Fragment>
              );
            })}
            {!isFetchingCalls && candidates && candidates?.length === 0 && (
              <tr>
                <td colSpan={12} style={{ minWidth: '100%' }}>
                  <Center p='lg'>No contacts</Center>
                </td>
              </tr>
            )}
            {isFetchingCalls && (
              <tr>
                <td colSpan={12} style={{ minWidth: '100%', width: '100%' }}>
                  <Center p='lg'>
                    <Loader size='sm' type='dots' />
                  </Center>
                </td>
              </tr>
            )}
          </Table.Tbody>
        </Table>
      </div>

      <Drawer
        opened={transcriptOpened}
        onClose={handleTranscriptClose}
        position='right'
        size='1100px'
        withOverlay={false}
        styles={{
          content: {
            borderLeft: '1px solid var(--salv-dark-0)',
            boxShadow:
              '0px 4px 12px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.08)',
          },
          header: {
            zIndex: 0,
          },
        }}
        withCloseButton={false}
        lockScroll={false}
      >
        <div
          style={{
            position: 'absolute',
            top: '6px',
            left: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            paddingLeft: '10px',
            paddingRight: '22px',
          }}
          onMouseEnter={() => setIsHeaderHovered(true)} // Add this line
          onMouseLeave={() => setIsHeaderHovered(false)} // Add this line
        >
          <CloseButton
            icon={<IconChevronsRight size={22} stroke={1.5} />}
            onClick={handleTranscriptClose}
          />
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            {isHeaderHovered &&
              showMarkAsUnreviewedButton &&
              !isFetchingTranscript && (
                <Tooltip
                  label='Mark as unreviewed'
                  position='bottom'
                  openDelay={400}
                  style={{ opacity: 0.8 }}
                >
                  <ActionIcon
                    size='sm'
                    style={{ width: 'fit-content' }}
                    variant='subtle'
                    onClick={handleMarkAsUnreviewed}
                  >
                    <IconNotification size={20} color='var(--salv-dark-5)' />
                  </ActionIcon>
                </Tooltip>
              )}
          </div>
        </div>
        {isFetchingTranscript ? (
          <Center p='lg'>
            <Loader size='sm' type='dots' />
          </Center>
        ) : transcriptError ? (
          <div
            style={{
              padding: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Title c={'black'}>Failed to retrieve call details</Title>
          </div>
        ) : (
          <Transcript
            transcript={transcriptData.transcript}
            requirementGradesList={transcriptData.requirementGradesList}
            candidateQuestions={transcriptData.candidateQuestions}
            callId={transcriptData.callId}
            overallGrade={transcriptData.overallGrade}
            completionRate={transcriptData.questionCompletionRate}
            candidateName={transcriptData.candidateName}
            lastCalled={transcriptData.lastCalled}
            allCalls={transcriptData.allCalls}
            callComplete={transcriptData.callComplete}
            webCall={transcriptData.webCall}
            candidateId={selectedCandidateId || ''}
            campaignId={campaignId}
            showNavigationShortcut={candidates.length > 1}
          />
        )}
        <SupportWindow
          allCalls={transcriptData.allCalls}
          campaignId={campaignId}
          candidateId={selectedCandidateId || ''}
        />
      </Drawer>
    </div>
  );
};

export default CampaignDetailsPage;
