import { useRef } from 'react';
import { useStytchMemberSession, useStytchMember } from '@stytch/react/b2b';
import env from 'env';

import axios from '../api/axiosConfig';

const SUPPORT_EMAIL_ADDRESS = 'support@salv.ai';

export const useSetLocalStorageIfNeeded = () => {
  const calledAtLeastOnce = useRef(false);
  // Set up local storage
  // Note: this is source of "truth" in front end for user, not the stytch tokens
  const { session } = useStytchMemberSession();
  const { member } = useStytchMember();

  if (useNeedsSupportSelection() || !session) {
    // don't set local storage.
    return;
  }
  if (!member?.email_address) {
    return;
  }
  if (member?.email_address === SUPPORT_EMAIL_ADDRESS) {
    // Do not set local storage
    return;
  }
  // else
  const normalMemberNotSetUp =
    member?.email_address !== SUPPORT_EMAIL_ADDRESS &&
    member?.email_address !== localStorage.getItem('email');

  if (normalMemberNotSetUp || !calledAtLeastOnce.current) {
    setLocalStorageFromEmail(member?.email_address as string);
    calledAtLeastOnce.current = true;
  }
};

export const useNeedsSupportSelection = (): boolean => {
  // Set up local storage
  // Note: this is source of "truth" in front end for user, not the stytch tokens

  const { member } = useStytchMember();

  const localStorageEmail = localStorage.getItem('email');
  if (
    member?.email_address === SUPPORT_EMAIL_ADDRESS &&
    (localStorageEmail === SUPPORT_EMAIL_ADDRESS || !localStorageEmail)
  ) {
    return true;
  }
  return false;
};

export const clearLocalStorage = () => {
  localStorage.removeItem('firstName');
  localStorage.removeItem('email');
  localStorage.removeItem('orgName');
  localStorage.removeItem('userId');
  localStorage.removeItem('orgId');
  localStorage.removeItem('calAPIKey');
  localStorage.removeItem('calEventTypeID');
};

export const setLocalStorageFromEmail = async (email: string) => {
  if (email === SUPPORT_EMAIL_ADDRESS) {
    alert('Error: cannot set local storage with support user');
    console.error('cannot set local storage with support user');
    return;
  }
  const payload = { email: email };
  try {
    const originalEmail = localStorage.getItem('email');
    const response = await axios.post(
      `${env.REACT_APP_SERVER_URL}/get_user_info`,
      payload
    );
    const { user } = response.data;
    const {
      email,
      org_id,
      first_name,
      last_name,
      org,
      user_id,
      cal_event_type_id,
      cal_api_key,
    } = user;
    localStorage.setItem('firstName', first_name || '');
    localStorage.setItem('lastName', last_name || '');
    localStorage.setItem('email', email || '');
    localStorage.setItem('orgName', org.name || '');
    localStorage.setItem('userId', user_id || '');
    localStorage.setItem('orgId', org_id || '');
    localStorage.setItem('calAPIKey', cal_api_key || '');
    localStorage.setItem('calEventTypeID', cal_event_type_id || '');

    if (originalEmail !== email) {
      // This is an annoying hack because components don't subscribe to loacl storage changes and now I've made it async
      // The bad result was that when users first logged in, all the components thought state was null
      // The better solution is a custom hook or moving to redux
      // The user doesn't notice the diference here since reloads are so fast and only happens on login, but it's super inelegant from a code perspective
      // Fine for the time being though
      window.location.reload();
    }
  } catch (error) {
    console.error('Error setting local storage from email:', error);
  } finally {
  }
};
