import React from 'react';
import { Indicator } from '@mantine/core';
import { useAtom } from 'jotai';
import { campaignInfoAtomForCandidate } from 'pages/editorv2/atoms';

interface ReviewIndicatorProps {
  campaignId: string;
  candidateId: string;
  review: 'Overall' | 'Call' | 'SMS' | 'Email';
}

const reviewKeyMap: Record<string, string> = {
  Overall: 'changesReviewed',
  Call: 'isCallReviewed',
  SMS: 'isSMSReviewed',
  Email: 'isEmailReviewed',
};

const ReviewIndicator = ({
  campaignId,
  candidateId,
  review,
}: ReviewIndicatorProps) => {
  const candidateCampaignInfoKey = candidateId + '_' + campaignId;
  const [candidateInfo] = useAtom(
    campaignInfoAtomForCandidate(candidateCampaignInfoKey)
  );

  const shouldReview = candidateInfo[reviewKeyMap[review]] === false;

  if (!shouldReview) {
    return null;
  }

  return (
    <Indicator
      color='blue'
      size='8px'
      style={{
        ...(review !== 'Overall' && { position: 'absolute', left: 12 }),
        zIndex: 1,
      }}
    />
  );
};

export default ReviewIndicator;
