import { useCallback } from 'react';
import env from 'env';
import { notifications } from '@mantine/notifications';
import { useAtom } from 'jotai';
import {
  candidateCampaignInfoDictAtom,
  candidateUpdateTimestampsAtom,
} from 'pages/editorv2/atoms';

import axios from '../../src/api/axiosConfig';

export type ChangesReviewed = {
  changesReviewed?: boolean;
  isCallReviewed?: boolean;
  isSMSReviewed?: boolean;
  isEmailReviewed?: boolean;
};

interface UpdateCandidateCampaignReviewArgs {
  campaignId: string;
  candidateId: string;
  userId: string;
  changesReviewed: ChangesReviewed;
}

export const useUpdateCandidateCampaignReview = () => {
  // Didn't use campaignInfoAtomForCandidate because to get the latest candidateId,
  // I had to use a useEffect in the CampaignDetailsPage
  const [candidateCampaignInfo, setCandidateCampaignInfoDictAtom] = useAtom(
    candidateCampaignInfoDictAtom
  );

  const [candidateUpdateTimestamp, setCandidateUpdateTimestampsAtom] = useAtom(
    candidateUpdateTimestampsAtom
  );

  const handleUpdateChangesReviewed = useCallback(
    async ({
      campaignId,
      candidateId,
      userId,
      changesReviewed,
    }: UpdateCandidateCampaignReviewArgs) => {
      const candidateCampaignInfoKey = candidateId + '_' + campaignId;
      if (!campaignId || !candidateId || !userId) {
        return;
      }

      const candidateInfo = candidateCampaignInfo[candidateCampaignInfoKey];
      const shouldUpdate = Object.entries(changesReviewed).some(
        ([key, value]) => value !== candidateInfo[key as keyof ChangesReviewed]
      );

      if (!shouldUpdate) {
        return;
      }

      const prevData = { ...candidateInfo };
      const prevTimestamp = candidateUpdateTimestamp[candidateCampaignInfoKey];

      try {
        // Optimistic UI update
        setCandidateCampaignInfoDictAtom((prev) => ({
          ...prev,
          [candidateCampaignInfoKey]: {
            ...prev[candidateCampaignInfoKey],
            ...changesReviewed,
          },
        }));
        setCandidateUpdateTimestampsAtom((prev) => ({
          ...prev,
          [candidateCampaignInfoKey]: Date.now(),
        }));

        const payload = {
          userId,
          ...changesReviewed,
          changesReviewed: changesReviewed.changesReviewed ?? true, // Default to true if undefined
        };

        await axios.post(
          `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/changes_reviewed/${campaignId}`,
          payload
        );
      } catch (err) {
        console.error('Error updating changes reviewed:', err);

        // Revert changes
        setCandidateCampaignInfoDictAtom((prev) => ({
          ...prev,
          [candidateCampaignInfoKey]: prevData,
        }));
        setCandidateUpdateTimestampsAtom((prev) => ({
          ...prev,
          [candidateCampaignInfoKey]: prevTimestamp,
        }));

        notifications.show({
          title: 'There was an error updating changes reviewed',
          message: err instanceof Error ? err.message : '',
          color: 'red',
        });
      }
    },
    [
      candidateCampaignInfo,
      candidateUpdateTimestamp,
      setCandidateCampaignInfoDictAtom,
      setCandidateUpdateTimestampsAtom,
    ]
  );

  return { handleUpdateChangesReviewed };
};
