import React, { useState } from 'react';
import {
  Stack,
  Text,
  Textarea,
  Title,
  ActionIcon,
  Modal,
  Divider,
  Alert,
  Button,
} from '@mantine/core';
import { IconCheck, IconPencil, IconAlertTriangle } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import env from 'env';
import axios from 'api/axiosConfig';
import { usePermissions } from 'hooks/usePermissions';

const orgId = localStorage.getItem('orgId');

const GenerateStep = ({ scriptInfo, generatingScript }) => {
  const [showModalConfirmGuidelineEdit, setShowModalConfirmGuidelineEdit] =
    useState(false);
  const [editingGuidelines, setEditingGuidelines] = useState(false);
  const { canEditCampaign } = usePermissions();

  return (
    <>
      <Modal.Root
        opened={showModalConfirmGuidelineEdit}
        onClose={() => {
          setShowModalConfirmGuidelineEdit(false);
        }}
        size='lg'
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title style={{ fontWeight: 'bold' }}>
              Script Guidelines Edit Warning Confirmation
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Divider />
          <Modal.Body>
            <br />
            <Alert
              color='yellow'
              icon={<IconAlertTriangle size={16} />}
              style={{
                padding: '16px',
                backgroundColor: 'var(--salv-warning-yellow)',
              }}
            >
              <div style={{ fontSize: '16px' }}>
                <b>Warning</b>: Changes to additional guidelines will affect all
                scripts and sequences
              </div>
            </Alert>
            <br />
            <div>
              Modifications to additional guidelines will be applied to{' '}
              <b>all jobs, scripts, and campaign sequences</b> in your
              organization.
            </div>
            <br />
            <br />
            <Divider />
            <div
              style={{
                display: 'flex',
                marginTop: '16px',
                gap: '20px',
              }}
            >
              <Button
                variant='outline'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  setShowModalConfirmGuidelineEdit(false);
                  setEditingGuidelines(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant='filled'
                color={'var(--salv-warning-yellow)'}
                style={{
                  marginRight: 'auto',
                  '--button-color': 'black',
                }}
                onClick={() => {
                  setShowModalConfirmGuidelineEdit(false);
                  setEditingGuidelines(true);
                }}
              >
                <IconAlertTriangle size={16} style={{ marginRight: '8px' }} />
                Acknowledge and Edit
              </Button>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      <Modal.Root
        opened={editingGuidelines}
        onClose={() => {
          setEditingGuidelines(false);
          setShowModalConfirmGuidelineEdit(false);
        }}
        size='lg'
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title style={{ fontWeight: 'bold' }}>
              Edit Additional Guidelines
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Divider />
          <Modal.Body>
            <br />
            <Alert
              color='yellow'
              icon={<IconAlertTriangle size={16} />}
              style={{
                padding: '16px',
                backgroundColor: 'var(--salv-warning-yellow)',
              }}
            >
              <div style={{ fontSize: '16px' }}>
                <b>Warning</b>: Changes to additional guidelines will affect all
                scripts and sequences
              </div>
            </Alert>
            <br />
            <div>
              Modifications to additional guidelines will be applied to{' '}
              <b>all jobs, scripts, and campaign sequences</b> in your
              organization.
            </div>
            <br />
            <Textarea
              className='fullHeightTextarea'
              placeholder='Additional guidelines'
              {...scriptInfo.getInputProps('scriptGenerationGuidelines')}
              style={{ marginTop: '16px', height: '200px' }}
            />
            <br />
            <Divider />
            <div
              style={{
                display: 'flex',
                marginTop: '16px',
                gap: '20px',
              }}
            >
              <Button
                variant='outline'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  setEditingGuidelines(false);
                }}
              >
                Discard
              </Button>
              <Button
                variant='light'
                style={{
                  marginRight: 'auto',
                  backgroundColor: 'var(--salv-warning-yellow)',
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  '--button-color': 'black',
                }}
                onClick={async () => {
                  try {
                    await axios.post(
                      `${env.REACT_APP_SERVER_URL}/script_generation_guidelines/${orgId}`,
                      {
                        scriptGenerationGuidelines:
                          scriptInfo.getValues().scriptGenerationGuidelines,
                      },
                      {
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      }
                    );
                    notifications.show({
                      title: 'Success',
                      message: 'Guidelines updated successfully',
                      color: 'green',
                    });
                    setEditingGuidelines(false);
                  } catch (error) {
                    notifications.show({
                      title: 'Error',
                      message: 'Failed to update guidelines',
                      color: 'red',
                    });
                  }
                }}
              >
                Save For All Jobs
              </Button>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      <Stack style={{ minHeight: '300px', height: '50vh' }}>
        <Title order={5}>Job Description (Optional)</Title>
        <Text fz='sm' c='dimmed'>
          Add a job description which can be used to generate a call script and
          requirements
        </Text>
        <Textarea
          className='fullHeightTextarea'
          placeholder='Paste in complete job description...'
          {...scriptInfo.getInputProps('jobDescription')}
          disabled={generatingScript}
          classNames={{
            input: !canEditCampaign ? 'view-only' : '',
          }}
        />
      </Stack>
      <Stack style={{ height: '40vh' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Title order={5}>Additional Generation Instructions (Optional)</Title>
          {canEditCampaign && (
            <ActionIcon
              variant={false ? 'filled' : 'subtle'}
              onClick={() => {
                if (!editingGuidelines) {
                  setShowModalConfirmGuidelineEdit(true);
                } else {
                  setEditingGuidelines(false);
                }
              }}
              style={{
                marginLeft: true ? '14px' : '8px',
              }}
            >
              {false ? <IconCheck size={16} /> : <IconPencil size={16} />}
            </ActionIcon>
          )}
        </div>
        <Text fz='sm' c='dimmed'>
          Additional instructions the AI will follow when generating the script
        </Text>

        <Textarea
          className='fullHeightTextarea'
          placeholder='Add guidelines...'
          {...scriptInfo.getInputProps('scriptGenerationGuidelines')}
          classNames={{
            input: 'view-only',
          }}
        />
      </Stack>
    </>
  );
};

export default GenerateStep;
