import React from 'react';
import { Stack, Textarea, TextInput, Title } from '@mantine/core';
import { usePermissions } from 'hooks/usePermissions';

const WebCallEmailStep = ({ scriptInfo }) => {
  const { canEditCampaign } = usePermissions();

  return (
    <>
      <Stack h='100%'>
        <Title order={5}>Interview Link Email</Title>
        <TextInput
          {...scriptInfo.getInputProps('webCallEmailSubject')}
          label='Subject'
          classNames={{
            input: canEditCampaign ? '' : 'view-only',
          }}
        />
        <Textarea
          className='fullHeightTextarea'
          {...scriptInfo.getInputProps('webCallEmailBody')}
          label='Body'
          classNames={{
            input: canEditCampaign ? '' : 'view-only',
          }}
        />
        <br />
      </Stack>
    </>
  );
};

export default WebCallEmailStep;
