import React, { useCallback, useState } from 'react';
import {
  IconTrash,
  IconGripVertical,
  IconHelp,
  IconPlus,
  IconDotsVertical,
} from '@tabler/icons-react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {
  ActionIcon,
  Group,
  Input,
  Textarea,
  Table,
  Tooltip,
  Button,
  Flex,
  Modal,
  Switch,
  Pill,
} from '@mantine/core';
import { usePermissions } from 'hooks/usePermissions';

import './ScreeningQuestionsStep.css';
import {
  RequirementImportance,
  CandidateRequirement,
  QuestionType,
} from '../types';
import ImportanceBadgeSelector, {
  IMPORTANCE_LABELS,
} from './ImportanceBadgeSelector';
import QuestionTypeBadgeSelector, {
  QUESTION_TYPE_LABELS,
} from './QuestionTypeBadgeSelector';

enum EditableRequirementField {
  Grading = 'grading',
  Question = 'question',
  Title = 'title',
}

export type RequriementEditState = {
  index: number | null; // null if no field is being edited
  fieldType: EditableRequirementField | null; // null if no specific field type is selected
};

export const EmptyRequirementEditState: RequriementEditState = {
  index: null,
  fieldType: null,
};

const ScreeningQuestionsStep = ({
  requirements,
  requirementsHandlers,
  requirementItemBeingEdited,
  setRequirementItemBeingEdited,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const { canEditCampaign } = usePermissions();
  const Field = canEditCampaign ? EditableField : NonEditableField;

  const handleDragStart = () => {
    // Exit editing mode when starting to drag
    // setRequirementBeingEdited(undefined);
    setRequirementItemBeingEdited(EmptyRequirementEditState);
    setIsDragging(true);
  };
  const handleDragEnd = useCallback(
    (result) => {
      setIsDragging(false);
      if (!result.destination) return;
      requirementsHandlers.reorder({
        from: result.source.index,
        to: result.destination.index,
      });
    },
    [requirementsHandlers]
  );

  const handleDeleteRequirement = useCallback(
    (i: number) => () => {
      setRequirementItemBeingEdited(EmptyRequirementEditState);
      // setRequirementBeingEdited(undefined);
      requirementsHandlers.remove(i);
    },
    [requirementsHandlers, setRequirementItemBeingEdited]
  );

  const handleAddRequirement = () => {
    requirementsHandlers.append({
      question: '',
      llmGradingInstructions: '',
      importance: RequirementImportance.Normal,
      failIfZero: false,
      questionType: QuestionType.Normal,
    });
    // setRequirementBeingEdited(requirements.length);
  };
  const handleFieldChange =
    (index: number, field: keyof CandidateRequirement) =>
    (value: string | RequirementImportance | boolean) => {
      requirementsHandlers.setItemProp(index, field, value);
      if (field === 'importance' && value === RequirementImportance.Info) {
        requirementsHandlers.setItemProp(index, 'failIfZero', false);
      }
      if (field === 'questionType' && value !== QuestionType.AutoGenerated) {
        requirementsHandlers.setItemProp(index, 'title', '');
      }
    };

  const [openEditIndex, setOpenEditIndex] = useState(-1);

  return (
    <>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <div>
          <Table className='requirementTable'>
            <Table.Thead>
              <Table.Tr>
                <Table.Th
                  // draggable
                  style={{
                    width: '3%',
                  }}
                />
                <Table.Th
                  style={{
                    width: '44%',
                  }}
                >
                  Question
                  <Input.Label required />
                  <Tooltip label='Questions worded as they will be asked during the screen'>
                    <IconHelp
                      size={16}
                      style={{
                        marginLeft: '4px',
                        position: 'relative',
                        top: '3px',
                        // color: '#868e96',
                      }}
                    />
                  </Tooltip>
                </Table.Th>
                <Table.Th
                  style={{
                    width: '46%',
                  }}
                >
                  Grading Instructions{' '}
                  <Tooltip label='Rules for how to score response on a scale from 0 to 100'>
                    <IconHelp
                      size={16}
                      style={{
                        marginLeft: '4px',
                        position: 'relative',
                        top: '3px',
                        // color: '#868e96',
                      }}
                    />
                  </Tooltip>
                </Table.Th>
                {/* <Table.Th
                  style={{
                    width: '12%',
                  }}
                >
                  Additional Settings{' '}
                  <Tooltip label='Advanced question settings for special use cases'>
                    <IconHelp
                      size={16}
                      style={{
                        marginLeft: '4px',
                        position: 'relative',
                        top: '3px',
                        // color: '#868e96',
                      }}
                    />
                  </Tooltip>
                </Table.Th> */}
                <Table.Th
                  style={{
                    width: '10%',
                  }}
                />
              </Table.Tr>
            </Table.Thead>
            <Droppable
              droppableId='requirements'
              isDropDisabled={!canEditCampaign}
            >
              {(provided) => (
                <Table.Tbody
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {requirements?.map(
                    (
                      {
                        question,
                        llmGradingInstructions,
                        importance,
                        failIfZero,
                        questionType,
                        title,
                      },
                      index
                    ) => {
                      const isIdxEdited =
                        requirementItemBeingEdited.index === index;
                      const fieldBeingEdited =
                        requirementItemBeingEdited.fieldType;

                      return (
                        <Draggable
                          key={index}
                          draggableId={`req-${index}`}
                          index={index}
                          isDragDisabled={!canEditCampaign}
                        >
                          {(provided, snapshot) => (
                            <>
                              <Table.Tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  background: snapshot.isDragging
                                    ? 'var(--salv-dark-0)'
                                    : 'transparent',
                                }}
                              >
                                <Table.Td
                                  {...provided.dragHandleProps}
                                  style={{
                                    width: '3%',
                                  }}
                                  className='gripTd'
                                >
                                  <IconGripVertical
                                    size={18}
                                    className='dragIcon'
                                  />
                                </Table.Td>
                                <Table.Td
                                  style={{
                                    width: '40%',
                                  }}
                                >
                                  {questionType ===
                                    QuestionType.AutoGenerated && (
                                    <div style={{ fontWeight: 'bold' }}>
                                      <Field
                                        editing={
                                          isIdxEdited &&
                                          fieldBeingEdited ===
                                            EditableRequirementField.Title
                                        }
                                        value={title}
                                        onChange={handleFieldChange(
                                          index,
                                          'title'
                                        )}
                                        placeholder='Generated Question Title'
                                        setEditing={() => {
                                          setRequirementItemBeingEdited({
                                            fieldType:
                                              EditableRequirementField.Title,
                                            index: index,
                                          });
                                        }}
                                        description={''}
                                        shaded={false}
                                      />
                                    </div>
                                  )}

                                  <Field
                                    editing={
                                      isIdxEdited &&
                                      fieldBeingEdited ===
                                        EditableRequirementField.Question
                                    }
                                    value={question}
                                    onChange={handleFieldChange(
                                      index,
                                      'question'
                                    )}
                                    placeholder='What is your highest level of education?'
                                    setEditing={() => {
                                      setRequirementItemBeingEdited({
                                        fieldType:
                                          EditableRequirementField.Question,
                                        index: index,
                                      });
                                    }}
                                    description={
                                      questionType === QuestionType.GradeOnly
                                        ? '* Will not be asked during screen. Grade extracted from entire conversation context'
                                        : questionType ===
                                            QuestionType.AutoGenerated
                                          ? '* Provide instructions to AI for how to generate the question during the screen'
                                          : ''
                                    }
                                    // descriptionAbove={
                                    //   questionType ===
                                    //   QuestionType.AutoGenerated
                                    // }
                                    shaded={
                                      questionType !== QuestionType.Normal
                                    }
                                  />
                                </Table.Td>
                                <Table.Td
                                  style={{
                                    width: '50%',
                                  }}
                                >
                                  <Field
                                    editing={
                                      isIdxEdited &&
                                      fieldBeingEdited ===
                                        EditableRequirementField.Grading
                                    }
                                    value={llmGradingInstructions}
                                    onChange={handleFieldChange(
                                      index,
                                      'llmGradingInstructions'
                                    )}
                                    setEditing={() => {
                                      setRequirementItemBeingEdited({
                                        fieldType:
                                          EditableRequirementField.Grading,
                                        index: index,
                                      });
                                    }}
                                    disabled={false}
                                    placeholder='Score 100 if the candidate has a bachelors or higher. Score 50 if the candidate graduated high school or equivalent. Score 0 if the candidate did not graduate high school.'
                                  />
                                </Table.Td>

                                <Table.Td
                                  onClick={(e) => e.stopPropagation()}
                                  style={{
                                    width: '5%',
                                  }}
                                >
                                  <Group w='max-content' gap='xs'>
                                    <div
                                      style={{ display: 'flex', gap: '8px' }}
                                    >
                                      <ActionIcon
                                        variant='subtle'
                                        color='red'
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleDeleteRequirement(index)();
                                        }}
                                      >
                                        <IconTrash size={16} />
                                      </ActionIcon>

                                      <Tooltip label='More options'>
                                        <ActionIcon
                                          variant='subtle'
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setOpenEditIndex(index);
                                          }}
                                        >
                                          <IconDotsVertical size={16} />
                                        </ActionIcon>
                                      </Tooltip>
                                    </div>
                                  </Group>
                                </Table.Td>
                                <Modal
                                  opened={openEditIndex === index}
                                  onClose={() => {
                                    setOpenEditIndex(-1);
                                  }}
                                  centered
                                  size='lg'
                                  withCloseButton
                                  title='Edit Question'
                                  overlayProps={{
                                    backgroundOpacity: 0.3,
                                    // blur: 3,
                                  }}
                                  key={index}
                                >
                                  <div
                                    style={{
                                      height: `calc(100% - 40px)`,
                                      paddingBottom: `40px`,
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      gap: '22px',
                                      marginBottom: '50px',
                                      overflow: 'scroll',
                                      width: '100%',
                                    }}
                                  >
                                    {questionType ===
                                      QuestionType.AutoGenerated && (
                                      <div
                                        style={{
                                          width: '100%',
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        <h4 className='section-label'>Title</h4>
                                        <Field
                                          editing={true}
                                          value={title}
                                          onChange={handleFieldChange(
                                            index,
                                            'title'
                                          )}
                                          placeholder='Title'
                                          setEditing={() => {
                                            setRequirementItemBeingEdited({
                                              fieldType:
                                                EditableRequirementField.Title,
                                              index: index,
                                            });
                                          }}
                                          description={''}
                                          shaded={false}
                                        />
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        width: '100%',
                                      }}
                                    >
                                      <h4 className='section-label'>
                                        Question
                                      </h4>
                                      <Field
                                        editing={true}
                                        value={question}
                                        onChange={handleFieldChange(
                                          index,
                                          'question'
                                        )}
                                        placeholder='What is your highest level of education?'
                                        setEditing={() => {
                                          setRequirementItemBeingEdited({
                                            fieldType:
                                              EditableRequirementField.Question,
                                            index: index,
                                          });
                                        }}
                                        description={
                                          questionType ===
                                          QuestionType.GradeOnly
                                            ? '* Will not be asked during screen. Grade extracted from entire conversation context'
                                            : questionType ===
                                                QuestionType.AutoGenerated
                                              ? '* Provide instructions to AI for how to generate the question during the screen'
                                              : ''
                                        }
                                        shaded={
                                          questionType !== QuestionType.Normal
                                        }
                                      />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                      <h4 className='section-label'>
                                        Grading Instructions
                                      </h4>
                                      <Field
                                        editing={true}
                                        value={llmGradingInstructions}
                                        onChange={handleFieldChange(
                                          index,
                                          'llmGradingInstructions'
                                        )}
                                        setEditing={() => {
                                          setRequirementItemBeingEdited({
                                            fieldType:
                                              EditableRequirementField.Grading,
                                            index: index,
                                          });
                                        }}
                                        disabled={false}
                                        placeholder='Score 100 if the candidate has a bachelors or higher. Score 50 if the candidate graduated high school or equivalent. Score 0 if the candidate did not graduate high school.'
                                      />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                      <h4 className='section-label'>
                                        Additional Settings
                                      </h4>
                                      {/* Importance Row */}
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          marginBottom: '10px', // Optional: Adds space between rows
                                        }}
                                      >
                                        {/* Left Side: Label and Icon */}
                                        <div>
                                          <h5 className='subsection-label'>
                                            Importance
                                          </h5>
                                          <p className='subsection-description'>
                                            Weight of the question score toward
                                            the overall score. Info is not
                                            weighted in the overall score
                                          </p>
                                        </div>

                                        {/* Right Side: BadgeSelector */}
                                        <div style={{ width: 'auto' }}>
                                          <ImportanceBadgeSelector
                                            value={importance}
                                            onChange={handleFieldChange(
                                              index,
                                              'importance'
                                            )}
                                          />
                                        </div>
                                      </div>

                                      {/* Fail If Zero Row */}
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          marginBottom: '10px', // Optional: Adds space between rows
                                        }}
                                      >
                                        {/* Left Side: Label and Icon */}
                                        <div>
                                          <h5 className='subsection-label'>
                                            Fail if Zero
                                          </h5>
                                          <p className='subsection-description'>
                                            Set overall candidate score to 0 if
                                            this question score is 0
                                          </p>
                                        </div>

                                        {/* Right Side: Switch */}
                                        <Switch
                                          // color='var(--salv-dark-6)'
                                          checked={failIfZero}
                                          onChange={(e) =>
                                            handleFieldChange(
                                              index,
                                              'failIfZero'
                                            )(e.currentTarget.checked)
                                          }
                                          disabled={
                                            importance ===
                                            RequirementImportance.Info
                                          }
                                        />
                                      </div>

                                      {/* Question Type */}
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          marginBottom: '10px', // Optional: Adds space between rows
                                        }}
                                      >
                                        {/* Left Side: Label and Icon */}
                                        <div>
                                          <h5 className='subsection-label'>
                                            Question Type
                                          </h5>
                                          <p className='subsection-description'>
                                            Choose question type. Grade Only is
                                            not asked during screen. Auto
                                            Generated is generated dynamically
                                            during the screen.
                                          </p>
                                        </div>

                                        {/* Right Side: Switch */}
                                        <div style={{ width: 'auto' }}>
                                          <QuestionTypeBadgeSelector
                                            value={questionType}
                                            onChange={handleFieldChange(
                                              index,
                                              'questionType'
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Modal>
                              </Table.Tr>
                              {importance !== RequirementImportance.Normal ||
                              questionType !== RequirementImportance.Normal ||
                              failIfZero ? (
                                <Table.Tr
                                  style={{
                                    display: isDragging ? 'none' : 'table-row',
                                  }}
                                >
                                  <Table.Td
                                    colSpan={12}
                                    style={{
                                      padding: 0,
                                      paddingBottom: '12px',
                                      paddingLeft: '40px',
                                      height: 'auto',
                                    }}
                                  >
                                    <div
                                      style={{ display: 'flex', gap: '12px' }}
                                    >
                                      <h4
                                        style={{
                                          margin: 0,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Additional Settings:
                                      </h4>
                                      <Pill.Group>
                                        <>
                                          {importance !==
                                            RequirementImportance.Normal && (
                                            <Pill size='sm' key='importance'>
                                              {IMPORTANCE_LABELS[importance]}
                                            </Pill>
                                          )}
                                          {questionType !==
                                            RequirementImportance.Normal && (
                                            <Pill size='sm' key='questionType'>
                                              {
                                                QUESTION_TYPE_LABELS[
                                                  questionType
                                                ]
                                              }
                                            </Pill>
                                          )}
                                          {failIfZero && (
                                            <Pill size='sm' key='failIfZero'>
                                              Fail on Zero
                                            </Pill>
                                          )}
                                        </>
                                      </Pill.Group>
                                    </div>
                                  </Table.Td>
                                </Table.Tr>
                              ) : (
                                <></>
                              )}
                              <Table.Tr
                                style={{
                                  display: isDragging ? 'none' : 'table-row',
                                }}
                              >
                                <Table.Td
                                  colSpan={12}
                                  style={{
                                    borderTop:
                                      index === requirements.length - 1
                                        ? ''
                                        : '1px solid var(--salv-dark-1)',
                                    padding: 0,
                                    paddingBottom: '8px',
                                    height: 0,
                                  }}
                                ></Table.Td>
                              </Table.Tr>
                            </>
                          )}
                        </Draggable>
                      );
                    }
                  )}
                  {provided.placeholder}
                </Table.Tbody>
              )}
            </Droppable>
          </Table>
        </div>
      </DragDropContext>
      {canEditCampaign && (
        <Flex justify='center'>
          <Button
            leftSection={<IconPlus size={20} />}
            onClick={(e) => {
              e.stopPropagation();
              handleAddRequirement();
            }}
            variant='outline'
          >
            Add Requirement
          </Button>
        </Flex>
      )}
    </>
  );
};

const NonEditableField = ({ value, description = '' }) => {
  return (
    <>
      <p
        style={{
          marginTop: 0,
          marginBottom: '10px',
          color: 'gray',
          fontSize: '12px',
        }}
      >
        {description}
      </p>
      <Textarea
        autosize
        minRows={1}
        value={value}
        className='editableField tableTextArea view-only'
        description={description}
        style={{ backgroundColor: 'var(--salv-dark-0)' }}
      />
    </>
  );
};

const EditableField = ({
  editing,
  value,
  onChange,
  placeholder,
  setEditing,
  disabled = false,
  description = '',
  shaded = false,
  descriptionAbove = false,
}) => {
  // if (editing) {
  return (
    <>
      {descriptionAbove && (
        <p
          style={{
            marginTop: 0,
            marginBottom: '10px',
            color: 'gray',
            fontSize: '12px',
          }}
        >
          {description}
        </p>
      )}
      <Textarea
        autosize
        minRows={1}
        value={disabled ? '' : value}
        onChange={(e) => onChange(e.target.value)}
        onClick={() => {
          setEditing();
        }}
        className={`editableField tableTextArea editableText ${editing ? 'editingBorder' : ''}`}
        placeholder={disabled ? '' : placeholder}
        disabled={disabled}
        // label='Input label'
        description={!descriptionAbove && description}
        style={{ backgroundColor: shaded ? 'var(--salv-dark-0)' : '' }}
        // unstyled
      />
    </>
  );
};

export default ScreeningQuestionsStep;
