// Import AuthProvider and useAuth hook
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { useStytchMemberSession, useStytchMember } from '@stytch/react/b2b';
import AdminTabsPage from 'pages/admin/AdminTabsPage';
import { AliveScope, KeepAlive } from 'react-activation';

import SelectSupportRole from './pages/login/SelectSupportRole';
import CustomStytchLogin from './pages/login/CustomStytchLoginPage';
import FinishInfoPage from './pages/login/FinishInfoPage';
import RedirectLinkHandler from './pages/login/RedirectLinkHandler';
import UserSettingsPage from './pages/admin/UserSettingsPage';
import SourcingPage from './pages/sourcing/SourcingPage';
import SideNavBar from './components/side-nav-bar/SideNavBar';
import OAuthRedirect from './pages/email-settings/OAuthRedirect';
import CampaignDetailsPage from './pages/campaign/details/CampaignDetailsPage';
import ResumeEditorPage from './pages/resume-editor/ResumeEditorPage';
import JobPostingTipsPage from './pages/job-posting-tips/JobPostingTipsPage';
import ScriptsPage from './pages/scripts/ScriptsPage';
import WebCallPage from './pages/web-call/WebCallPage';
import { checkLocalServerRunning } from './serverCheck';
import {
  useNeedsSupportSelection,
  useSetLocalStorageIfNeeded,
} from './utils/userInfoUtils';
import ScriptTabsPage from './pages/scripts/ScriptTabsPage';

const Layout = () => {
  const location = useLocation();
  const hideSideNavBarRoutes = [
    '/login',
    '/authenticate',
    '/finish-creation',
    '/web-interview',
  ];

  const shouldHideSideNavBar = hideSideNavBarRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    <div
      style={{
        // display: 'flex',
        height: '100%',
        minHeight: '100%',
        maxHeight: '100vh',
        display: hideSideNavBarRoutes.includes(location.pathname)
          ? 'contents'
          : 'flex',
      }}
    >
      {/* Conditionally render SideNavBar if not on certain routes */}
      {!shouldHideSideNavBar && <SideNavBar />}
      <Routes>
        <Route path='/authenticate' element={<RedirectLinkHandler />} />
        <Route path='/login' element={<LoginRoute />} />
        <Route
          path='/finish-creation'
          element={<ProtectedRoute component={FinishInfoPage} />}
        />
        <Route
          path='/scripts/script-editor/new/:campaignId'
          element={<ProtectedRoute component={ScriptTabsPage} />}
        />
        <Route
          path='/scripts/script-editor/new-phone/:campaignId'
          element={<ProtectedRoute component={ScriptTabsPage} />}
        />
        <Route
          path='/scripts/script-editor/new-web/:campaignId'
          element={<ProtectedRoute component={ScriptTabsPage} />}
        />
        <Route
          path='/scripts/script-editor/:campaignId'
          element={<ProtectedRoute component={ScriptTabsPage} />}
        />
        <Route
          path='/web-interview/:encodedId'
          element={<ProtectedRoute component={WebCallPage} />}
        />
        <Route
          path='/web-interview/test-call/:encodedData'
          element={<ProtectedRoute component={WebCallPage} />}
        />
        <Route
          path='/scripts'
          element={<ProtectedRoute component={ScriptsPage} />}
        />
        <Route
          path='/resume-editor'
          element={<ProtectedRoute component={ResumeEditorPage} />}
        />
        <Route
          path='/oauth-redirect'
          element={<ProtectedRoute component={OAuthRedirect} />}
        />
        <Route
          path='/campaigns/campaign/:campaignId'
          element={<ProtectedRoute component={CampaignDetailsPage} />}
        />
        <Route
          path='/sourcing'
          element={<ProtectedRoute component={SourcingPage} keepAlive={true} />}
        />
        <Route
          path='/user-settings'
          element={<ProtectedRoute component={UserSettingsPage} />}
        />
        <Route
          path='/settings'
          element={<ProtectedRoute component={AdminTabsPage} />}
        />
        <Route
          path='/job-posting-tips'
          element={<ProtectedRoute component={JobPostingTipsPage} />}
        />
        <Route path='/' element={<ProtectedRoute component={ScriptsPage} />} />
      </Routes>
    </div>
  );
};

const App = () => {
  useEffect(() => {
    checkLocalServerRunning();
  }, []);

  useSetLocalStorageIfNeeded();

  return (
    <MantineProvider
      theme={{
        fontFamily: 'Inter, system-ui, sans-serif',
      }}
    >
      <ModalsProvider>
        <Notifications position='top-right' />
        <AliveScope>
          <Router>
            <Layout />
          </Router>
        </AliveScope>
      </ModalsProvider>
      <Notifications position='top-right' />
    </MantineProvider>
  );
};

// ProtectedRoute component checks if user is logged in and either shows the EditorPage or redirects to login
const ProtectedRoute = ({ component: Component, keepAlive = false }) => {
  const { session } = useStytchMemberSession();
  const { member } = useStytchMember();
  const needsSupportSelection = useNeedsSupportSelection();
  // TODO: this is very redeundant to the home router redirect logic
  if (!session) {
    return <Navigate replace to='/login' />;
  }
  if (!member.trusted_metadata.salv_user_id) {
    return <FinishInfoPage />;
  }
  if (needsSupportSelection) {
    return <SelectSupportRole />;
  }
  return keepAlive ? (
    <KeepAlive>
      <Component />
    </KeepAlive>
  ) : (
    <Component />
  );
};

// LoginRoute component does the opposite; it redirects to the main page if logged in, otherwise shows LoginPage
const LoginRoute = () => {
  const { session } = useStytchMemberSession();
  const needsSupportSelection = useNeedsSupportSelection();

  if (needsSupportSelection) {
    return <SelectSupportRole />;
  }

  return !session ? <CustomStytchLogin /> : <Navigate replace to='/' />;
};

export default App;
