import React, { useEffect, useState, useCallback } from 'react';
import { Button, ActionIcon, Textarea, Avatar } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import env from 'env';
import { campaignInfoAtomForCandidate } from 'pages/editorv2/atoms';
import { notifications } from '@mantine/notifications';
import './Transcript.css';
import { useAtom } from 'jotai';
import axios from 'api/axiosConfig';

interface CommentProps {
  campaignId: string;
  candidateId: string;
  userId: string;
}

interface Comment {
  comment_id: string;
  text: string;
  user_email: string;
  user_first_name: string;
  user_last_name: string;
  created_at: string;
  modified_at?: string;
}

const Comments = ({ campaignId, candidateId, userId }: CommentProps) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState('');
  const [isSavingComment, setIsSavingComment] = useState(false);

  const candidateCampaignInfoKey = candidateId + '_' + campaignId;
  const [, setCandidateInfo] = useAtom(
    campaignInfoAtomForCandidate(candidateCampaignInfoKey)
  );

  const fetchComments = useCallback(async () => {
    try {
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/comments/${campaignId}`
      );
      setComments(response.data || []);
      setCandidateInfo({
        hasComments: response.data.length > 0,
        _fetchedAt: undefined,
      });
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  }, [candidateId, campaignId, setCandidateInfo]);

  const handleSaveComment = async () => {
    if (!newComment.trim()) return;

    setIsSavingComment(true);
    try {
      await axios.post(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/comments/${campaignId}`,
        {
          text: newComment,
          userId: userId,
        }
      );
      await fetchComments();
      setNewComment('');
    } catch (error) {
      console.error('Error saving comment:', error);
    } finally {
      setIsSavingComment(false);
    }
  };

  const handleDeleteComment = async (commentId: string) => {
    try {
      await axios.delete(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/comments/${campaignId}/${commentId}`
      );
      await fetchComments();
    } catch (error) {
      console.error('Error deleting comment:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to delete comment',
        color: 'red',
      });
    }
  };

  useEffect(() => {
    fetchComments();
  }, [candidateId, campaignId, fetchComments]);

  const renderComments = () => (
    <div className='comments-section'>
      <h4 style={{ marginTop: '0px', marginBottom: '12px' }}>Comments</h4>
      <div className='comments-list'>
        {comments.map((comment) => (
          <div key={comment.comment_id} className='comment'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  variant='filled'
                  color='var(--mantine-color-blue-3)'
                  size={36}
                  name={comment.user_first_name + ' ' + comment.user_last_name}
                  style={{ margin: '6px', marginRight: '14px' }}
                />
                <div>
                  <div>
                    {comment.user_first_name + ' ' + comment.user_last_name}
                  </div>
                  <small style={{ display: 'block' }}>
                    {new Date(comment.created_at).toLocaleString(undefined, {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    })}
                  </small>
                </div>
              </div>
              <ActionIcon
                variant='subtle'
                color='red'
                onClick={() => handleDeleteComment(comment.comment_id)}
              >
                <IconTrash size={16} />
              </ActionIcon>
            </div>
            <p style={{ padding: '8px', fontSize: '14px' }}>{comment.text}</p>
          </div>
        ))}
      </div>
      <div className='new-comment'>
        <Textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder='Add a comment...'
          style={{ borderRadius: '12px' }}
          minRows={1}
        ></Textarea>
        <div
          style={{
            display: 'flex',
            width: '100%',
            visibility: newComment ? 'visible' : 'hidden',
            gap: '8px',
          }}
        >
          <Button
            size='xs'
            onClick={() => setNewComment('')}
            disabled={isSavingComment || !newComment.trim()}
            style={{ padding: '0px 30px', marginLeft: 'auto' }}
            // color='var(--mantine-color-blue-6)'
            variant='outline'
          >
            Discard
          </Button>
          <Button
            size='xs'
            onClick={handleSaveComment}
            disabled={isSavingComment || !newComment.trim()}
            style={{ padding: '0px 30px' }}
            color='var(--mantine-color-blue-6)'
          >
            {isSavingComment ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  );

  return <>{renderComments()}</>;
};

export default Comments;
